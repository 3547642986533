import React from "react";
import icon1 from "../../images/about/home-trend-up.png";
import icon2 from "../../images/about/user.png";
import icon3 from "../../images/about/briefcase.png";
import icon5 from "../../images/about/home.png";
import image from "../../images/tech/Rectangle 7 (1) (1).png";
import image1 from "../../images/tech/Group 18 (1).png";
import "./tech.css";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import Header from "../../components/header/Header";

export default function Tech() {
  return (
    <div className="tech">
      <div className="aarenergy">
        <div className="agricbusiness">
          <div className="home">
            <Header />

            <div className="width two">
              <div className="reusebody">
                <div className="content">
                  <p>AAR TECHNOLOGY</p>
                  <p>
                    AAR Technology is an industry leader in the Information
                    Technology sector of the Nigerian economy, providing
                    world-class IT solutions to individuals and businesses
                    across different industries.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="building">
            <div className="width">
              <div className="content">
                <div className="text">
                  <span>AAR Switch Ltd</span> <br />
                  <br />
                  <ul>
                    <li>
                      Established in 2017, AAR Switch Ltd is an innovative IT
                      services provider offering extensive IT support and
                      solutions, and services designed to serve all business
                      sizes. 
                    </li>
                    <br />
                    <br />
                    <li>
                      Our relentless commitment to client satisfaction and an
                      in-depth understanding of technology issues is what
                      differatientes us from competitors. We also pursue
                      cost-efficient solutions to support our clients in their
                      quest to achieve results and sustainable competitive
                      advantage. 
                    </li>
                    <br />
                    <br />
                    <li>
                      We leverage our team of diverse professionals, industry
                      experience, and an understanding of what works best to
                      deliver the best value for customers and help them take
                      ownership of their digital processes.
                    </li>
                  </ul>
                </div>
                <div className="image">
                  <img src={image} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="width two">
            <div className="holding">
              <div className="content">
                <div className="business">
                  <div className="top">Our Business at a Glance</div>
                  <div className="bottom">
                    <div className="four">
                      <div className="icon">
                        <img src={icon1} alt="" />
                      </div>
                      <p>
                        <CountUp start={0} end={4} redraw={false}>
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                        </CountUp>
                        +
                      </p>
                      <p>years of exceptional service delivery</p>
                    </div>
                    <div className="four">
                      <div className="icon">
                        <img src={icon2} alt="" />
                      </div>
                      <p>
                        <CountUp start={0} end={3} redraw={false}>
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                        </CountUp>
                        +
                      </p>
                      <p>IT Products</p>
                    </div>
                    <div className="four">
                      <div className="icon">
                        <img src={icon3} alt="" />
                      </div>
                      <p>
                        <CountUp start={0} end={5} redraw={false}>
                          {({ countUpRef, start }) => (
                            <VisibilitySensor onChange={start} delayedCall>
                              <span ref={countUpRef} />
                            </VisibilitySensor>
                          )}
                        </CountUp>
                        +
                      </p>
                      <p>Providing Technology Solutions to 5+ industry</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="reusebash two">
            <div className="width">
              <div className="content">
                <div className="img">
                  <img src={image1} alt="" />
                </div>
                <div className="text two">
                  <p>Our Products</p>
                  <div className="shape">
                    <div className="integrity">
                      <img src={icon5} alt="" />
                      <p>Payment Processing Solutions </p>
                    </div>
                    <div className="integrity">
                      <img src={icon5} alt="" />
                      <p>Cyber Security</p>
                    </div>
                    <div className="integrity">
                      <img src={icon5} alt="" />
                      <p>VAS Content Aggregation</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
