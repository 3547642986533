import React from "react";
import "./footer.css";
import image from "../../images/homepage/aar logo chosen 1 (1).png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="content">
        <div className="logo">
          <img src={image} alt="" />
          <div className="tp">
            {/* <p>Terms & Conditions</p>
            <p>Privacy Policy</p> */}
          </div>
        </div>
        <div className="list">
          <div className="three">
            <p>OUR SERVICES</p>

            <p>
              <Link to="/agricbusiness">AAR Agribusiness</Link>{" "}
            </p>
            <p>
              <Link to="/agricenergy"> AAR Energy</Link>
            </p>
            <p>
              <Link to="/agrictech"> AAR Technology</Link>
            </p>
            <p>
              <Link to="/sustainability">Sustainability</Link>
            </p>
          </div>
          <div className="three">
            <p>COMPANY</p>
            <p>
              <Link to="/aboutus">About us</Link>
            </p>

            <p>
              <Link to="/contact">Contact Us</Link>
            </p>
            <p>
              <Link to="/career">Career</Link>
            </p>
            <p>
              <Link to="/team">Team</Link>
            </p>
          </div>
          <div className="three">
            <p>Contact us</p>
            <p>Facebook</p>
            <p>Linkedin</p>
            <p>
              <a href="https://t.co/xLkZK2dijq">Twitter</a>
            </p>
            <p>
              <a href="https://www.instagram.com/aarholdings/">Instagram</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
