import React from "react";
import book from "../../images/homepage/book.png";
import diamonds from "../../images/homepage/diamonds.png";
import "./vision.css";

export default function Vision() {
  return (
    <div className="down">
      <div className="width">
        <div className="vision">
          <div className="content">
            <img src={book} alt="" />
            <p>Our Vision</p>
            <p>
              To be the leading and the most recognized group harnessing
              Africa’s resources to deliver value to all our clients and
              stakeholders.
            </p>
          </div>
          <div className="content">
            <img src={diamonds} alt="" />
            <p>Our Mission</p>
            <p>
              To build globally recognized businesses impacting lives across
              Africa.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
