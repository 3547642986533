import React from "react";
import image from "../../images/about/Rectangle 23 (1).png";
import two from "../../images/about/Rectangle 23 (1) (1).png";
import three from "../../images/about/Rectangle 23 (2).png";
import read from "../../images/about/Frame 7.svg";
import "./ourbus.css";
import { Link } from "react-router-dom";

export default function Ourbus() {
  return (
    <div className="ourbus" id="section-id">
      <div className="width">
        <div className="content">
          <div className="up">
            <p>Our Businesses</p>
            <p>
              Our businesses are grouped into three broad groups. These groups
              hold our 5 subsidiary companies.
            </p>
          </div>

          <div className="below">
            <div className="image">
              <img src={image} alt="" />
              <div className="talk">
                <p>AAR Agribusiness</p>
                <p>AAR Rice Company Ltd </p>
                <p>AAR Starch Derivatives Ltd </p>
                <p>AAR Agri-Services Ltd</p>
              </div>
              <Link to="/agricbusiness">
                <img src={read} alt="" />
              </Link>
            </div>
            <div className="image">
              <img src={two} alt="" />
              <div className="talk">
                <p>AAR Energy</p>
                <p>AAR Energy Ltd </p>
                <p className="go"> i</p>
                <p className="go"> i</p>
              </div>
              <Link to="/agricenergy">
                <img src={read} alt="" />
              </Link>
            </div>

            <div className="image">
              <img src={three} alt="" />

              <div className="talk">
                <p>AAR Technology</p>
                <p>AAR Switch Ltd</p>
                <p className="go"> i</p>
                <p className="go"> i</p>
              </div>
              <Link to="/agrictech">
                <img src={read} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
