import React from "react";
import { Link } from "react-router-dom";
import "./aboutus.css";

export default function Aboutus() {
  return (
    <div className="aboutus">
      <div className="width">
        <p>About Us</p>
        <p>
          We are a diversified and integrated group with holdings in the
          Agri-business, Energy, and Technology sectors.
        </p>
        <Link to="/aboutus">
          <button>Read More</button>{" "}
        </Link>
      </div>
    </div>
  );
}
