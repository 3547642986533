import React from "react";
import Header from "../../components/header/Header";
import image from "../../images/agricbusiness/Rectangle 7 (1).png";
import image1 from "../../images/about/Rectangle 23 (1).png";
import image11 from "../../images/agricbusiness/Group 13 (1) (1).png";
import "./team.css";

export default function Team() {
  return (
    <div className="team">
      <div className="agricbusiness">
        <div className="home">
          <Header />

          <div className="width two">
            <div className="reusebody">
              <div className="content">
                <p>Our Team</p>
                <p>
                  The AAR Leadership consists of seasoned and accomplished
                  industry professionals, with decades of combined experience
                  working across the Nigerian economy.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="building">
          <div className="width">
            <div className="content">
              <div className="text">
                <span>BASHIR, Abdullahi Haske</span> <br />
                <br />
                GROUP MANAGING DIRECTOR
                <br />
                <br />
                A serial entrepreneur, private investor and philanthropist,
                Abdullahi Haske Bashir is the Founder and Group Managing
                Director of AAR Holdings, and the Chairman of the Energy and
                Agribusiness Subsidiaries of the company.
                <br />
                <br />
                A passionate believer in the Nigerian story, Abdullahi believes
                that Nigerians are in the best position to develop Nigeria. More
                than a profit-making entity, AAR Holdings is his contribution to
                national development, providing solutions for clients, as well
                as being a sustainable source of livelihood for staff and host
                communities.
                <br />
                <br />
                As the Group Managing Director, Abdullahi provides grounded
                business leadership and critical stakeholder support for all key
                projects of AAR Holdings' subsidiaries. He also has an
                outstanding capacity for identifying unique business
                opportunities across various sectors, improving the company’s
                commercial portfolio.
                <br />
                <br />
                He also founded The AB Haske Foundation, a foremost humanitarian
                and non-governmental organisation foundation in Nigeria.
                <br />
                <br />
                Abdullahi holds a Diploma in Political Science from the
                University of Abuja, Nigeria.
              </div>
              <div className="image">
                <img src={image} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="reusebash two">
          <div className="width">
            <div className="content">
              <div className="img">
                <img src={image11} alt="" />
              </div>
              <div className="text">
                <b>GROUP EXECUTIVE DIRECTOR </b>
                <br />
                <br />
                Abdulrahman Bashir Haske is the Group Executive Director (GED)
                of AAR Holdings.
                <br />
                <br />
                He oversees the group's architectural and construction dealings.
                Equipped with practical experience in Contract Management,
                Business Planning and Operations, Negotiation and Business
                Improvement, Abdulrahman is a proven team leader and
                communications expert.
                <br />
                <br />
                A skilled IT professional, Abdulrahman holds a Bachelor’s degree
                in Information Systems from the American University of Nigeria,
                and an MSc. in Computer Science from the Nigerian Turkish Nile
                University. He is currently concluding an executive MBA in the
                prestigious Alliance Manchester Business School.
                <br />
                <br />
                He also chaired several leadership roles at his alma mater,
                American University of Nigeria, notable of which are Polo Club
                President and Assistant Director, Academics.
              </div>
            </div>{" "}
          </div>
        </div>

        {/* after two sections  */}

        <div className="building">
          <div className="width">
            <div className="content">
              <div className="text">
                <span>WILLIAMS, Oladipo </span> <br />
                <br />
                GROUP CHIEF OPERATING OFFICER
                <br />
                <br />
                Oladipo Olajide Williams is the Group Chief Operating Officer
                (Group COO) of AAR Holdings. An astute Business Specialist and
                Project Manager, Williams is equipped with over 15 years'
                experience leading teams across West Africa. He has also led a
                career across some of Nigeria’s largest companies operating in
                Oil & Gas, Logistics and Investment sectors. As Group Chief
                Operating Officer of AAR Holdings, Williams leverages his
                technical expertise in financial modelling and business
                valuation to drive the group’s growth, resulting in increased
                visibility and profitability for the group. Williams holds a
                Bachelor’s degree in Systems Analysis from the University of
                Lagos, and is an alumnus of The Lagos Business School (AMP
                Programme). He is also a member of the Institute of Directors,
                Nigeria.
              </div>
              <div className="image">
                <img src={image1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
