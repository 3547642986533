import React from "react";
import Header from "../../components/header/Header";
import "./career.css";
import icon1 from "../../images/about/Vector.png";
import image from "../../images/career/Rectangle 7.png";

export default function Career() {
  return (
    <div className="career">
      <div className="agricbusiness">
        <div className="home">
          <Header />

          <div className="width two">
            <div className="reusebody">
              <div className="content">
                <p>CAREERS</p>
                <p>
                  At AA&R Investment Group, we have imbibed proactive workstyles
                  that foster a collaborative and productive work environment.
                  Our work environment supports professionalism and integrity,
                  encouraging high performance across our teams.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="building">
          <div className="width">
            <div className="content">
              <div className="text">
                <span>Our Hiring Process</span> <br />
                <br />
                Our hiring process ensures that only the best is clearly
                filtered and recruited into our workforce and we also ensure
                continuous professional development for our employees by
                investing significant resources in unique training and
                development programs in line with our mission to recruit, retain
                and reward a high-performance workforce.
                <br />
                <br />
                We are honoured to be considered for your next career move, and
                we look forward to hearing from you.
                <br />
                <br />
                <b>Disclaimer</b>
                <br />
                <br />
                <div className="disclaim">
                  <div className="if">
                    <img src={icon1} alt="" />
                    <p>
                      If you have a keen interest in working with us please go
                      ahead and apply below. If none of the roles here suits
                      you, you can send an unsolicited application via{" "}
                      <a href="mailto: recruitment@aarinvest.com">
                        recruitment@aarinvest.com
                      </a>
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="if">
                    <img src={icon1} alt="" />
                    <p>
                      We do not advertise job openings publicly, but we’re open
                      to receiving applications for various role categories on
                      our website and will contact you
                    </p>
                  </div>{" "}
                  <br />
                  <br />
                  <div className="if">
                    <img src={icon1} alt="" />
                    <p>
                      if your application meets our criteria. • You can apply by
                      filling the form below or sending in your professional
                      resume with your cover letter to our careers mail above
                      and we will get back to you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="image">
                <img src={image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
