import React from "react";
import Aboutus from "../../components/aboutus/Aboutus";
import Bodycontent from "../../components/bodycontent/Bodycontent";
import Business from "../../components/business/Business";
import Header from "../../components/header/Header";
import Rebranding from "../../components/rebranding/Rebranding";
import Reusebash from "../../components/reusebash/Reusebash";
import Vision from "../../components/vision/Vision";

export default function Homepage() {
  return (
    <div className="homepage">
      <div className="home">
        <Header />

        <div className="width">
          <Bodycontent />
        </div>
      </div>
      <Vision />

      <div className="width">
        <Rebranding />
      </div>

      <div className="width">
        <Business />
      </div>

      <Reusebash />

      <Aboutus />
    </div>
  );
}
