import React from "react";
import Header from "../../components/header/Header";
import icon1 from "../../images/about/Vector.png";
import icon2 from "../../images/about/v2.png";
import icon3 from "../../images/about/v3.png";
import image from "../../images/sustain/Group 22 (1).png";
import "./contact.css";

export default function Contact() {
  return (
    <div className="contact">
      <div className="aarenergy">
        <div className="agricbusiness">
          <div className="home">
            <Header />
            <div className="width two">
              <div className="reusebody">
                <div className="content">
                  <p>CONTACT US</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>

          <div className="reusebash two">
            <div className="width">
              <div className="content">
                <div className="img">
                  <img src={image} alt="" />
                </div>
                <div className="text two">
                  <p></p>

                  <div className="shape">
                    <div className="integrity">
                      <img src={icon1} alt="" />
                      <p></p>
                      <p>
                        For more information and enquiries, <br />
                        <span>visit our website at:</span> <br />
                        www.aarholdings.com
                      </p>
                    </div>
                    <div className="integrity">
                      <img src={icon2} alt="" />
                      <p>
                        <span> Or you can mail us at:</span> <br />
                        <a href="mailto: info@aar-holdings.com">
                          info@aar-holdings.com 
                        </a>
                      </p>
                    </div>
                    <div className="integrity">
                      <img src={icon3} alt="" />
                      <p>
                        <span>Head Office:  </span> <br />
                        <a
                          href="http://maps.google.com/?q=952/953 Idejo Street, Victoria Island, Lagos State,
                        Nigeria"
                        >
                          952/953 Idejo Street, Victoria Island, Lagos State,
                          Nigeria.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
