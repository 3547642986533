import React from "react";
import Header from "../../components/header/Header";
import image1 from "../../images/sustain/Group 21 (1).png";
import "./sustainability.css";
import icon5 from "../../images/about/home.png";
import image from "../../images/sustain/Rectangle 7 (2) (1).png";

export default function Sustainability() {
  return (
    <div className="sustainability">
      <div className="aarenergy">
        <div className="agricbusiness">
          <div className="home">
            <Header />
            <div className="width two">
              <div className="reusebody">
                <div className="content">
                  <p>Collective Enthusiasm for the Collective Good.</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>

          <div className="building">
            <div className="width">
              <div className="content">
                <div className="text">
                  At AAR Holdings, we are enthusiasts of positive environmental,
                  social and governance practices, and we ensure that this
                  enthusiasm is shared across all our businesses, by every
                  employee, partner, and stakeholders of our company.
                  <br />
                  <br />
                  We believe that sustainability is a key factor in achieving
                  long-term success and growth and we are committed to promoting
                  only the best practices in Nigeria and beyond.
                </div>
                <div className="image">
                  <img src={image} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="reusebash two">
            <div className="width">
              <div className="content">
                <div className="img">
                  <img src={image1} alt="" />
                </div>
                <div className="text two">
                  <p></p>

                  <div className="shape">
                    <div className="integrity">
                      <img src={icon5} alt="" />
                      <p></p>
                      <p>
                        <span> Health, Safety and Environment</span> <br />
                        Our operations are in accordance with global Health,
                        Safety and Environment standards and we consistently
                        make efforts to mitigate risks and hazards to our
                        employees, host communities and the environment at
                        large.
                      </p>
                    </div>
                    <div className="integrity">
                      <img src={icon5} alt="" />
                      <p>
                        <span> Ethics & Compliance </span> <br />
                        We are a culturally diverse organisation, and as such,
                        we have built a work culture that values diversity and
                        fosters inclusion, as this is pivotal to growth,
                        innovation, productivity and profitability.
                      </p>
                    </div>
                    <div className="integrity">
                      <img src={icon5} alt="" />
                      <p>
                        <span> Corporate Social Responsibility </span> <br />
                        At the core of our business is our Corporate Social
                        Responsibility. We concern ourselves with the
                        development of local communities and one way we achieve
                        this is by generating shared value with our host
                        communities through job creation and skill enhancement.
                      </p>
                    </div>
                    <div className="integrity">
                      <img src={icon5} alt="" />
                      <p>
                        <span> Local Content</span> <br />
                        We ensure the use of local labour, goods, services and
                        equipment as raw materials from production to
                        distribution. This is to encourage local production and
                        utilisation of local resources.
                      </p>
                    </div>
                    <div className="integrity">
                      <img src={icon5} alt="" />
                      <p>
                        <span> Security</span> <br />
                        We strive to imbibe global standards and diligently
                        observe established security protocols in the day-to-day
                        running of all our businesses.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
