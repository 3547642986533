import React from "react";
import "./bodycontent.css";
import house from "../../images/homepage/Mask group (1) (1).png";
import child from "../../images/homepage/Mask gro.png";

export default function Bodycontent() {
  return (
    <div className="bodycontent">
      <div className="up">
        <div className="coming">
          <p>
            Coming <br />
            Together <br />
            as one
          </p>
          <p>
            Welcome to AAR Holdings, an African business impacting people,
            transforming communities & changing lives.
          </p>

          <div className="children">
            <img src={child} alt="" />
          </div>
        </div>
        <div className="image">
          <img src={house} alt="" />
        </div>
      </div>
    </div>
  );
}
