import React from "react";
import "./rebranding.css";

export default function Rebranding() {
  return (
    <div className="rebranding">
      <p>
        Our rebranding to AAR Holdings was birthed to adequately capture the
        scope of who we are as a company - an indigenous business with a mission
        to deliver better value to all its stakeholders, host communities, and
        staff. This positions us as a stronger force, offering our services and
        products under a unified identity.”
      </p>
      <p>BASHIR, Abdullahi Haske</p>
      <p>Group Managing Director</p>
    </div>
  );
}
