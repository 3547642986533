import React from "react";
import book from "../../images/homepage/book.png";
import diamonds from "../../images/homepage/diamonds.png";

import "./building.css";

export default function Building() {
  return (
    <>
      <div className="building">
        <div className="width">
          <div className="content">
            <div className="text">
              We leverage innovation and technology to improve productivity and
              efficiency across our businesses. We  also employ our vast
              industrial expertise, data-driven insights and our biggest assets
              - our people, to tackle complex problems and add value to millions
              of lives in Nigeria and across the continent.
              <br />
              <br />
              We are powering the nation’s growth and investing in a better
              tomorrow for the country by giving back to the communities where
              our businesses are located. Through our investments, we are also
              helping to create jobs and opportunities for Nigerians. 
              <br />
              <br />
              Everything we do at AAR Holdings is guided by strong principles
              that continually reflect our commitment to organizational and
              national development.
              <br />
              <br />
              Our goal to positively transform the Nigerian economy and
              reposition the continent of Africa globally is set in motion. 
            </div>
            <div className="image">{/* <img src={image} alt="" /> */}</div>
          </div>
        </div>
      </div>
      <div className="down two">
        <div className="width">
          <div className="vision">
            <div className="content">
              <img src={book} alt="" />
              <p>Our Vision</p>
              <p>
                To be the leading and the most recognized group harnessing
                Africa’s resources to deliver value to all our clients and
                stakeholders.
              </p>
            </div>
            <div className="content">
              <img src={diamonds} alt="" />
              <p>Our Mission</p>
              <p>
                To build globally recognized businesses impacting lives across
                Africa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
