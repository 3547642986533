import React, { useState } from "react";
import "./header.css";
import logo from "../../images/homepage/aar logo chosen 1.png";
import menu from "../../images/homepage/menub.svg";
import { Link } from "react-router-dom";

export default function Header() {
  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <div className="header">
      <div className="width">
        <div className="content">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="nav">
            <ul>
              <li>
                <Link to="/aboutus"> About Us</Link>
              </li>
              <li>
                Our Businesses
                <div className="libus">
                  <div className="content">
                    <p>
                      {" "}
                      <Link to="/agricbusiness">AAR Agribusiness</Link>{" "}
                    </p>
                    <p>
                      <Link to="/agricenergy"> AAR Energy</Link>
                    </p>
                    <p>
                      <Link to="/agrictech"> AAR Technology</Link>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <Link to="/sustainability">Sustainability </Link>
              </li>
            </ul>
          </div>
          <Link to="/contact">
            <div className="button">Contact Us</div>
          </Link>

          <div className="menu" onClick={handleToggle}>
            <img src={menu} alt="" />
          </div>
        </div>
      </div>

      {/* mobile view  */}
      <div className={isActive ? "active" : "mobile"}>
        <div className="three">
          <div onClick={handleToggle} className="logo">
            <img src={logo} alt="" />
          </div>

          <div className="menu" onClick={handleToggle}>
            <img src={menu} alt="" />
          </div>
        </div>

        <div className="products">
          <Link to="/aboutus">ABOUT US</Link>
        </div>

        <div className="products">
          <Link to="/agricbusiness">AAR AGRIBUSINESS</Link>
        </div>

        <div className="products">
          <Link to="/agricenergy"> AAR ENERGY</Link>
        </div>

        <div className="products">
          <Link to="/agrictech"> AAR TECHNOLOGY</Link>
        </div>
        <div className="products">
          <Link to="/sustainability">SUSTAINABILITY</Link>
        </div>
        <div className="products">
          <Link to="/contact">CONTACT US </Link>
        </div>
      </div>
    </div>
  );
}
