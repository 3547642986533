import React from "react";
import "./reusebody.css";

export default function Reusebody() {
  return (
    <div className="reusebody">
      <div className="content">
        <p>
          Innovating for a sustainable <br /> future in Africa.
        </p>
        <p>
          AAR Holdings is a Nigerian group with businesses across the <br />
          Agribusiness, Energy and Technology sectors. We provide an array of{" "}
          <br />
          innovative products and solutions that help businesses achieve their{" "}
          <br />
          goals faster.
        </p>
      </div>
    </div>
  );
}
