import React from "react";
import "./contribute.css";
// import image from "../../images/about/low-angle-shot-tall-high-rise-modern-business-building-with-clear-sky copy 1 (1).png";

export default function Contribute() {
  return (
    <div className="contribute">
      <div className="width">
        <div className="content">
          <div className="image">{/* <img src={image} alt="" /> */}</div>
          <div className="text">
            At AAR Holdings, we are dedicated to contributing to the nation's
            growth and development. We employ innovative technologies to deliver
            only the best products and services to our customers and clients.
            These products and services also help to improve the quality of life
            of Nigerians.
            <br />
            <br />
            By identifying and making certain brownfield and greenfield
            investments, we are creating better avenues for business and
            opportunities to drive economic development, create jobs and boost
            national productivity. We also provide local farmers and workers the
            opportunity to scale their businesses and contribute to the global
            market.
            <br />
            <br />
            Beyond profit-making, AAR Holdings is committed to making Nigeria a
            better place for everyone by providing opportunities for sustainable
            development through technology and innovation.
          </div>
        </div>
      </div>
    </div>
  );
}
