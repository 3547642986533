import React from "react";
import "./reusebash.css";
import image from "../../images/homepage/Group 8 (1).png";

export default function Reusebash() {
  return (
    <div className="reusebash">
      <div className="width">
        <div className="content">
          <div className="img">
            <img src={image} alt="" />
          </div>
          <div className="text">
            <p>
              “16 years ago, we started a journey to build a world-class group
              that identifies, incubates, & nurtures businesses across the
              Agri-Business, Energy, and ICT sub sectors.
              <br />
              <br />
              Everything we do at AAR Holdings is guided by strong principles
              that continually reflects our commitment to National and
              continental development anchored on our philosophy of responsible
              entrepreneurship™
            </p>
            <p>BASHIR, Abdullahi Haske</p>
            <p>Group Managing Director</p>
          </div>
        </div>
      </div>
    </div>
  );
}
