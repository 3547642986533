import React from "react";
import "./business.css";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";

export default function Business() {
  return (
    <div className="business">
      <div className="top">Our Businesses at a Glance</div>
      <div className="bottom">
        <div className="four">
          <p>
            <CountUp start={0} end={5} redraw={false}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            +
          </p>
          <p>Business Subsidiaries</p>
        </div>
        <div className="four">
          <p>
            <CountUp start={0} end={100} redraw={false}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            +
          </p>
          <p>Direct Employees</p>
        </div>
        <div className="four">
          <p>
            <CountUp start={0} end={3} redraw={false}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            +
          </p>
          <p>Industry Sectors</p>
        </div>
        <div className="four">
          <p>
            <CountUp start={0} end={25} redraw={false}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            +
          </p>
          <p>Products & Services</p>
        </div>
      </div>
    </div>
  );
}
