import React from "react";
import "./aarenegy.css";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import icon1 from "../../images/about/home-trend-up.png";
import icon2 from "../../images/about/user.png";
import icon3 from "../../images/about/briefcase.png";
import icon4 from "../../images/about/designtools.png";
import icon5 from "../../images/about/home.png";
import image from "../../images/agricenergy/Rectangle 7 (1).png";
import image1 from "../../images/agricenergy/Group 17 (1).png";
import Header from "../../components/header/Header";

export default function Aarenergy() {
  return (
    <div className="aarenergy">
      <div className="agricbusiness">
        <div className="home">
          <Header />

          <div className="width two">
            <div className="reusebody">
              <div className="content">
                <p>AAR ENERGY</p>
                <p>
                  AAR Energy is a world-class energy company providing
                  innovative solutions across the various sectors of the
                  Nigerian energy industry
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="building">
          <div className="width">
            <div className="content">
              <div className="text">
                <span>AAR Energy Ltd</span> <br />
                <br />
                <ul>
                  <li>
                    AAR Energy Ltd is an indigenous exploration & production
                    company providing innovative approaches to the exploration
                    and production of Oil and Gas in Africa. 
                  </li>
                  <br />
                  <br />
                  <li>
                    Our service capacity spans the entire upstream Oil & Gas
                    value chain, exceeding the expectations of our clients and
                    ensuring sustainable returns for all relevant stakeholders. 
                  </li>
                  <br />
                  <br />
                  <li>
                    We provide full-cycle support of onshore and offshore assets
                    from exploration to decommissioning, advising from initial
                    planning through design, construction, operations to
                    maintenance and shutdown.
                  </li>
                  <br />
                  <br />
                  <li>
                    At AAR Energy, we are fully invested in commitment to
                    environmental service. Our CSR operations ensure that we
                    contribute meaningfully to the socio-economic development of
                    the continent of Africa; starting from Nigeria.
                  </li>
                </ul>
              </div>
              <div className="image">
                <img src={image} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="width two">
          <div className="holding">
            <div className="content">
              <div className="business">
                <div className="top">Our Business at a Glance</div>
                <div className="bottom">
                  <div className="four">
                    <div className="icon">
                      <img src={icon1} alt="" />
                    </div>
                    <p>
                      <CountUp start={0} end={50} redraw={false}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      +
                    </p>
                    <p>Projects</p>
                  </div>
                  <div className="four">
                    <div className="icon">
                      <img src={icon2} alt="" />
                    </div>
                    <p>
                      <CountUp start={0} end={100} redraw={false}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      +
                    </p>
                    <p>Employees </p>
                  </div>
                  <div className="four">
                    <div className="icon">
                      <img src={icon3} alt="" />
                    </div>
                    <p>
                      <CountUp start={0} end={6} redraw={false}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      +
                    </p>
                    <p>Exploration & Production Operations</p>
                  </div>
                  <div className="four">
                    <div className="icon">
                      <img src={icon4} alt="" />
                    </div>
                    <p>
                      <CountUp start={0} end={4} redraw={false}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      +
                    </p>
                    <p>Assets</p>
                  </div>
                  <div className="four">
                    <div className="icon">
                      <img src={icon4} alt="" />
                    </div>
                    <p>
                      <CountUp start={0} end={1200} redraw={false}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                    </p>
                    <p>People empowered through CSR </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="reusebash two">
          <div className="width">
            <div className="content">
              <div className="img">
                <img src={image1} alt="" />
              </div>
              <div className="text two">
                <p>Our Services</p>
                <div className="shape">
                  <div className="integrity">
                    <img src={icon5} alt="" />
                    <p>Search and Exploration</p>
                  </div>
                  <div className="integrity">
                    <img src={icon5} alt="" />
                    <p>Well Construction</p>
                  </div>
                  <div className="integrity">
                    <img src={icon5} alt="" />
                    <p>Exploration/Drilling</p>
                  </div>
                  <div className="integrity">
                    <img src={icon5} alt="" />
                    <p>Extraction/Development </p>
                  </div>
                  <div className="integrity">
                    <img src={icon5} alt="" />
                    <p>Acquisition</p>
                  </div>
                  <div className="integrity">
                    <img src={icon5} alt="" />
                    <p>Asset Integrity Management</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
