import React from "react";
import Building from "../../components/Building/Building";
import Contribute from "../../components/contribute/Contribute";
import Header from "../../components/header/Header";
import Ourbus from "../../components/ourbus/Ourbus";
import Reusebody from "../../components/reusebody/Reusebody";
import image from "../../images/about/Group 12 (1) (1).png";
import icon1 from "../../images/about/home-trend-up.png";
import icon2 from "../../images/about/user.png";
import icon3 from "../../images/about/briefcase.png";
import icon4 from "../../images/about/designtools.png";
import icon5 from "../../images/about/home.png";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import "./about.css";

export default function About() {
  return (
    <div className="about">
      <div className="home">
        <Header />

        <div className="width">
          <Reusebody />
        </div>
      </div>

      <Building />

      <div className="width">
        <div className="holding">
          <div className="content">
            <div className="business">
              <div className="top">AAR Holdings in numbers</div>
              <div className="bottom">
                <div className="four">
                  <div className="icon">
                    <img src={icon1} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={5} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </p>
                  <p>Business Subsidiaries</p>
                </div>
                <div className="four">
                  <div className="icon">
                    <img src={icon2} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={100} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </p>
                  <p>Direct Employees</p>
                </div>
                <div className="four">
                  <div className="icon">
                    <img src={icon3} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={3} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </p>
                  <p>Industry Sectors</p>
                </div>
                <div className="four">
                  <div className="icon">
                    <img src={icon4} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={25} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </p>
                  <p>Products & Services</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="reusebash two">
        <div className="width">
          <div className="content">
            <div className="img">
              <img src={image} alt="" />
            </div>
            <div className="text two">
              <p>OUR PHILOSOPHY</p>
              <div className="shape">
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>Integrity</p>
                </div>
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>Impact</p>
                </div>
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>Professionalism</p>
                </div>
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>Responsible Entrepreneurship</p>
                </div>
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>Knowledge & Transformation</p>
                </div>
              </div>

              <p>Powering the nation’s growth</p>

              <p>
                As a group, all our processes and practices are aligned towards
                a series of objectives. They are:
              </p>

              <ul>
                <li>Contributing on a national scale</li>
                <li>Creating value for the well-being of people</li>
                <li>Improving quality of life</li>
                <li>Delivering the best services</li>
                <li>Providing opportunities.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Contribute />

      <Ourbus />
    </div>
  );
}
