import React from "react";
import Header from "../../components/header/Header";
import image from "../../images/agricbusiness/Rectangle 7 (1).png";
import image11 from "../../images/agricbusiness/Group 13 (1) (1).png";
import image12 from "../../images/agricbusiness/Group 11 (1).png";
import image13 from "../../images/agricbusiness/Group 15 (1).png";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import image1 from "../../images/about/Rectangle 23 (1).png";
import image2 from "../../images/agricbusiness/Rectangle 24 (2) (1).png";
import icon1 from "../../images/about/home-trend-up.png";
import icon2 from "../../images/about/user.png";
import icon3 from "../../images/about/briefcase.png";
import icon4 from "../../images/about/designtools.png";
import icon5 from "../../images/about/home.png";

// import Reusebody from "../../components/reusebody/Reusebody";
import "./agricbusiness.css";

export default function Agricbusiness() {
  return (
    <div className="agricbusiness">
      <div className="home">
        <Header />

        <div className="width two">
          <div className="reusebody">
            <div className="content">
              <p>AAR AGRIBUSINESS</p>
              <p>
                The AAR Agribusiness is primarily focused on the production,
                processing and sale of quality agricultural products.​ Our
                holding companies are:
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="building">
        <div className="width">
          <div className="content">
            <div className="text">
              <span>AAR Rice Company Ltd</span> <br />
              <br />
              <ul>
                <li>
                  AAR Rice Company Ltd is a pioneer producer of indigenous rice
                  products in Nigeria. We are committed to the production of the
                  finest, nutritional, export-quality products for our local and
                  international markets. 
                </li>
                <br />
                <br />
                <li>
                  We work directly with our farm estates and network of
                  outgrowers to farm, produce and distribute superior rice
                  products. Our robust supply strategy is designed to improve
                  lives and enhance food security. 
                </li>
                <br />
                <br />
                <li>
                  We invest in community development through partnerships with
                  smallholder farmers, training and social welfare programs. One
                  of these initiatives is the Demsa Integrated Rice Production
                  Project (DIRPP), an industrial-scale agricultural project
                  sited in Demsa L.G.A of Adamawa State. 
                </li>
                <br />
                <br />
                <li>
                  AAR Rice Company operates under eco-friendly standards, while
                  ensuring that appropriate measures are maintained to cater to
                  health, security and safety of workers, communities and
                  everyone concerned both on and off site.
                </li>
              </ul>
            </div>
            <div className="image">
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="width two">
        <div className="holding">
          <div className="content">
            <div className="business">
              <div className="top">Our Business at a Glance</div>
              <div className="bottom">
                <div className="four">
                  <div className="icon">
                    <img src={icon1} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={25000} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </p>
                  <p>tonne Production Capacity</p>
                </div>
                <div className="four">
                  <div className="icon">
                    <img src={icon2} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={16250} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </p>
                  <p>tonnes a year of parboiled and milled rice.</p>
                </div>
                <div className="four">
                  <div className="icon">
                    <img src={icon3} alt="" />
                  </div>
                  <p>4</p>
                  <p>Years of Existence</p>
                </div>
                <div className="four">
                  <div className="icon">
                    <img src={icon4} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={8} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </p>
                  <p>Tton per hour Rice mill</p>
                </div>
                <div className="four">
                  <div className="icon">
                    <img src={icon4} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={5000} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </p>
                  <p>hectare cultivation Estate </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="reusebash two">
        <div className="width">
          <div className="content">
            <div className="img">
              <img src={image11} alt="" />
            </div>
            <div className="text two">
              <p>Our Products</p>
              <div className="shape">
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>AAR Premium Long Grain Rice</p>
                </div>
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>AAR Rice Bran Oil</p>
                </div>
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>AAR Rice Bran</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* after two sections  */}

      <div className="building">
        <div className="width">
          <div className="content">
            <div className="text">
              <span>AAR Starch Derivatives Ltd </span> <br />
              <br />
              <ul>
                <li>
                  AAR Starch Derivative Ltd, formerly known as H&W Starch
                  Derivatives Co. Ltd., is one of the fastest-growing producers
                  of locally grown cassava tubers in Nigeria. 
                </li>
                <br />
                <br />
                <li>
                  Since our establishment in 2015, we have empowered over 6000
                  farmers, and by employing cutting-edge technology to produce
                  high-quality cassava derivatives for both local and export
                  demands, we are positioned to elevate the Nigerian
                  agribusiness landscape to international capacity. 
                </li>
                <br />
                <br />
                <li>
                  We invest in community development by partnering with the
                  local farming communities. AAR Starch Derivatives Ltd has
                  helped, and is still helping to enhance the lives of members
                  of its host community, Gwaria community of Kaiama L.G.A, Kwara
                  State.
                </li>
              </ul>
            </div>
            <div className="image">
              <img src={image1} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="width two">
        <div className="holding">
          <div className="content">
            <div className="business">
              <div className="top">Our Business at a Glance</div>
              <div className="bottom">
                <div className="four">
                  <div className="icon">
                    <img src={icon1} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={30000} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </p>
                  <p>Ton per Day Starch Production Plant </p>
                </div>
                <div className="four">
                  <div className="icon">
                    <img src={icon2} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={6000} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </p>
                  <p>Farmers empowered </p>
                </div>
                <div className="four">
                  <div className="icon">
                    <img src={icon3} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={10000} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </p>
                  <p>Hectare Cassava Cultivation Estate</p>
                </div>
                <div className="four">
                  <div className="icon">
                    <img src={icon4} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={12} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </p>
                  <p>Ton per Day Glucose and Dextrose Production Plant.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="reusebash two">
        <div className="width">
          <div className="content">
            <div className="img">
              <img src={image12} alt="" />
            </div>
            <div className="text two">
              <p>Our Products</p>
              <div className="shape">
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>AAR Native Starch</p>
                </div>
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>AAR Drilling Starch</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* after two sections  */}

      <div className="building">
        <div className="width">
          <div className="content">
            <div className="text">
              <span>AAR Agri-Services Ltd </span> <br />
              <br />
              <ul>
                <li>
                  AAR Agriservices Ltd is the Agri-support subsidiary of AAR
                  Holdings. We are committed to growing the Nigerian
                  Agricultural sector by enhancing opportunities for
                  agribusiness operations in local communities. 
                </li>{" "}
                <br />
                <br />
                <li>
                  AAR Agriservices Ltd is an agri-support company created to
                  provide smallholder farmers the much-needed support to
                  increase agricultural production and move away from
                  subsistence farming. 
                </li>{" "}
                <br />
                <br />
                <li>
                  We provide smallholder farmers financial and in-kind support
                  such as fertilisers, modern farming technologies, market
                  information, and business skills to help them improve their
                  livelihood.
                </li>
                <br />
                <br />
              </ul>
            </div>
            <div className="image">
              <img src={image2} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="width two">
        <div className="holding">
          <div className="content">
            <div className="business">
              <div className="top">Our Business at a Glance</div>
              <div className="bottom">
                <div className="four">
                  <div className="icon">
                    <img src={icon1} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={70} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </p>
                  <p>markets opened </p>
                </div>
                <div className="four">
                  <div className="icon">
                    <img src={icon2} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={1000} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </p>
                  <p>farmers reached</p>
                </div>
                <div className="four">
                  <div className="icon">
                    <img src={icon3} alt="" />
                  </div>
                  <p>
                    <CountUp start={0} end={9} redraw={false}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </p>
                  <p>
                    trillion MT <br />
                    seedlings provided
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="reusebash two">
        <div className="width">
          <div className="content">
            <div className="img">
              <img src={image13} alt="" />
            </div>
            <div className="text two">
              <p>Our Services</p>
              <div className="shape">
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>Capacity building</p>
                </div>
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>Financing </p>
                </div>
                <div className="integrity">
                  <img src={icon5} alt="" />
                  <p>Operational support</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
