import "./App.css";
import Homepage from "./pages/homepage/Homepage";
import { HashRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/ScrollToTop";
import About from "./pages/aboutus/Index";
import Agricbusiness from "./pages/agricbusiness/Agricbusiness";
import Aarenergy from "./pages/aarenergy/Aarenergy";
import Tech from "./pages/tech/Tech";
import Sustainability from "./pages/sustainability/Sustainability";
import Contact from "./pages/contact/Contact";
import Team from "./pages/team/Team";
import Career from "./pages/career/Career";

function App() {
  return (
    <div className="app">
      <HashRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Homepage />
                <div className="width">
                  <Footer />
                </div>
              </>
            }
          ></Route>
          <Route path="/aboutus" element={<About />} />
          <Route path="/agricbusiness" element={<Agricbusiness />} />
          <Route path="/agricenergy" element={<Aarenergy />} />
          <Route path="/agrictech" element={<Tech />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/team" element={<Team />} />
          <Route path="/career" element={<Career />} />
        </Routes>
        <div className="width">
          <Footer />
        </div>
      </HashRouter>
    </div>
  );
}

export default App;
